'use client'
import Script from 'next/script'

const cookiebotReloadOnAccept = () => {
    return <Script onLoad={() => {
        (function () {
            window.addEventListener("CookiebotOnAccept", function (e) {
                if (window.Cookiebot.changed) {
                    document.location.reload();
                }
            });
        })();
    }} />
}
export default cookiebotReloadOnAccept;